module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3_xypbhv6agk7flffqefr7htkhny/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"allisonmcbride.me","short_name":"aamb.me","start_url":"/","icon":"src/images/AIcon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"acb6d90607587041baa2dc45dd2392f2"},
    },{
      plugin: require('../node_modules/.pnpm/@chakra-ui+gatsby-plugin@3.1.3_@chakra-ui+react@2.10.3_@emotion+react@11.13.3_react@18.3.1__@_oiy63poob6qfqlh77xejdwl25i/node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3.1_react@18.3.1__react@18.3.1__5ew7aqhtbcntmgy4quedt5ipai/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
